
body {
    font-family: "Quicksand", sans-serif;
    /* display: grid; */
    place-items: center;
    height: 100vh;
    /* background: #7f7fd5;
    background: linear-gradient(to right, #91eae4, #86a8e7, #7f7fd5); */
  }
  
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin-block: 2rem;
    gap: 3rem;
  }
  
  .card11 img {
    max-width: 100%;
    height: 400px;
    display: block;
    object-fit: cover;
  }
  
  .card11 {
    display: flex;
    flex-direction: column;
    width: clamp(20rem, calc(20rem + 2vw), 22rem);
    overflow: hidden;
    box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    background: #ECE9E6;
  background: linear-gradient(to right, #FFFFFF, #ECE9E6);
  
  }
  
  
  
  .card__body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
  
  
  .tag {
    align-self: flex-start;
    padding: .25em .75em;
    border-radius: 1em;
    font-size: .75rem;
  }
  
  .tag + .tag {
    margin-left: 1.5em;
  }
  
 
  
  .card__body h4 {
    font-size: 1.5rem;
    text-transform: capitalize;
    color: red;
  }
  
  .card__footer {
    display: flex;
    padding: 1rem;
    margin-top: auto;
  }
  
  .user {
    display: flex;
    gap: .5rem;
  }
  
  .user__image {
    border-radius: 50%;
  }
  
  .user__info > small {
    color: #666;
  }


  @media(max-width: 600px){
    .card11 {
        display: flex;
        flex-direction: column;
        width: auto;
        overflow: hidden;
        box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
        border-radius: 1em;
        background: #ECE9E6;
      background: linear-gradient(to right, #FFFFFF, #ECE9E6);
      
      }

  }