@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --primaryBackground: #212222;
  --secondaryBackground: #2f3030;
  --primaryColor: #e0a80d;
  --greyDark: #7d8792;
  --headingColor: #2d2e2e;
  --white: #fff;
  --black: #000;
  --sliver: #bac8d3;
  --blue: #4daffa;
  --pink: rgb(248, 194, 78);
}
body {
  background-color: var(--white);
  color: var(--white);
  font-family: sans-serif;
}
a {
  color: var(--greyDark);
  text-decoration: none;
  transition: 0.5s;
}
a:hover {
  color: var(--primaryColor);
}
p {
  color: var(--black);
  font-size: 15px;
  line-height: 30px;
  margin: 20px 0;
}

.container {
  max-width: 80%;
  margin: auto;
}
.flexsb {
  display: flex;
  justify-content: space-between;
}
button {
  border: none;
  outline: none;
  background: none;
  font-size: 17px;
  /* background-color: var(--primaryColor); */
  /* color: var(--white); */
  padding: 15px 50px;
  border-radius: 50px;
  margin-top: 20px;
  cursor: pointer;
}
button:hover {
  color: var(--black);
}
.fontSize {
  font-size: 20px;
}
.primaryBtn {
  background: none;
  border: 2px solid var(--primaryColor);
}
/*---------header-------------*/
header {
  background-color: var(--headingColor);
  height: 10vh;
  line-height: 10vh;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
header a {
  margin-left: 40px;
}
.toggle {
  cursor: pointer;
  color: var(--white);
  display: none;
}
@media screen and (max-width: 768px) {
  header .nav {
    display: none;
  }
  header a {
    width: 100%;
    display: block;
    color: var(--white);
  }
  .toggle {
    display: block;
    font-size: 25px;
    background: none;
  }
  .hideMenu {
    display: block;
    position: absolute;
    top: 10vh;
    left: 0;
    width: 100%;
    background-color: var(--headingColor);
  }
}
/*---------header-------------*/
section {
  height: 90vh;
  display: flex;
  align-items: center;
}
.flex {
  display: flex;
}

/*---------hero-------------*/
/* .hero {
  position: relative;
} */
.hero::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* background: linear-gradient(rgba(6, 6, 6, 0.235), rgba(16, 16, 16, 0.8)), url("../public/images/2.jpg");  */
  background-image: url("./Images/rs-Heropage.jpeg");
  background-size: cover;
  /* background-attachment: fixed; */

  width: 100%;
  height: 90vh;
}

.heroContent {
  max-width: 100%;
  /* margin: auto; */
  text-align: left;
}
/* .hero h1 {
  font-size: 40px;
  font-weight: 600;
  color: var(--blue);
} */

.automobile {
  position: relative;
}
.automobile::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  background-image: url("../public/images/2.jpg");
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
  width: 100%;
  height: 90vh;
}

/*---------hero-------------*/
.heading {
  font-size: 30px;
  color: var(--primaryColor);
  font-weight: 600;
  text-align: center;
  margin-bottom: 60px;
}

/*---------about-------------*/
.about .primaryBtn {
  margin-left: 20px;
}
.about .heading {
  text-align: left;
  margin: 0;
}
.left {
  width: 35%;
}
.right {
  width: 65%;
}

/*---------about-------------*/
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

/*---------services-------------*/
.services .box {
  background-color: var(--blue);
  padding: 35px;
}
.services i {
  color: var(--primaryColor);
}
.services h3 {
  margin-top: 20px;
}
/*---------services-------------*/
.grid4 {
  grid-template-columns: repeat(4, 1fr);
  height: 50vh;
  place-items: center;
  text-align: center;
}

/*---------counter-------------*/
.counter {
  margin-top: 80px;
  position: relative;
}
.hero.counter::after {
  background-image: url("./Images/rs-Heropage.jpeg");
  height: 50vh;
}
.counter h1 {
  margin: 0;
}
/*---------counter-------------*/
/*---------portfolio-------------*/

article {
  margin-top: 50px;
  margin-bottom: 80px;
}
article img {
  width: 100%;
  height: 100%;
}
article .box {
  position: relative;
  transition: 0.5s;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  transition: 0.5s;
  z-index: 2;
}
.overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primaryColor);
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
}
article .box:hover .overlay::after,
article .box:hover .overlay {
  opacity: 1;
}
.catButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}
.catButton button {
  text-transform: capitalize;
  margin-right: 20px;
}
/*---------portfolio-------------*/
/*---------testimonials-------------*/
.testimonials {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
/* .testimonials::after {
  /* background: linear-gradient(rgba(42, 8, 42, 0.8), rgba(40, 24, 24, 0.8)), url("../public/images/testimonials-bg.png");  Chrome 10-25, Safari 5.1-6 */
/* background: linear-gradient(rgba(29, 7, 29, 0.8), rgba(43, 22, 22, 0.8)), url("../public/images/testimonials-bg.png"); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
/* background-image: url("../public/images/testimonials-bg.png"); */
/* filter: blur(4px); */

.testimonials::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(rgba(6, 6, 6, 0.235), rgba(16, 16, 16, 0.8)),
    url("../public/images/testimonials-bg.png");
  /* background-image: url("../public/images/2.jpg"); */
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
  width: 100%;
  height: 90vh;
}

.testimonials .box {
  max-width: 60%;
}
.testimonials i {
  background-color: var(--white);
  font-size: 20px;
  padding: 15px;
  margin: 30px 0;
  display: inline-block;
  color: var(--primaryColor);
}
.testimonials .img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 50%;
  border: 3px solid var(--primaryColor);
}
.testimonials img {
  width: 100px;
  height: 100px;
  background-size: cover;
  border-radius: 50%;
  object-fit: cover;
}
label {
  color: var(--primaryColor);
  margin: 10px 0;
  display: inline-block;
}
/*---------testimonials-------------*/
/*---------blog-------------*/
.blog2 img {
  width: 100%;
}
.blog2 {
  background: linear-gradient(180deg, #003da6, #001f53);
}
.blog2 .box {
  background-color: var(--blue);
  border-radius: 10px;
}
.blog2 .text {
  padding: 10px 30px;
}

/*---------blog-------------*/
/*---------contact-------------*/
.contact {
  margin: 20px 0;
}
.contact .right,
.contact .left {
  padding: 20px;
}
.contact .flex input:nth-child(1) {
  margin-right: 10px;
}
.contact button,
.contact textarea,
.contact input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #3485c4;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--black);
  opacity: 1; /* Firefox */
}
/*---------contact-------------*/
/*---------footer-------------*/
footer {
  text-align: center;
  background-color: var(--primaryBackground);
  padding: 70px;
  margin-top: 50px;
}
footer i {
  margin: 5px;
  color: var(--primaryColor);
}
/*---------footer-------------*/
@media screen and (max-width: 768px) {
  .hero {
    height: 60vh;
  }
  .heroContent {
    max-width: 80%;
  }

  .hero::after {
    background-image: url("./Images/rs-Heropage.jpeg");
    background-size: cover;
    object-fit: cover;
    width: 100%;
    height: 60vh;
  }

  section {
    height: auto;
    padding-top: 50px;
  }
  .contact .flexsb,
  .about .flex {
    flex-direction: column;
  }
  .right,
  .left {
    width: 100%;
  }
  .grid4,
  .grid3 {
    grid-template-columns: repeat(2, 1fr);
  }
  article {
    margin: 0;
    padding-bottom: 50px;
  }
}

/* {/* <style>  
    /* #Mega Menu Styles
    –––––––––––––––––––––––––––––––––––––––––––––––––– */
.mega-menu {
  display: none;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
}

/* {/*   
    /* #hoverable Class Styles
    –––––––––––––––––––––––––––––––––––––––––––––––––– */
.hoverable {
  position: static;
}

.hoverable > a:after {
  content: "\25BC";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}

.hoverable:hover .mega-menu {
  display: block;
}

/*     
    {/* /* #toggle Class Styles
    –––––––––––––––––––––––––––––––––––––––––––––––––– */

.toggleable > label:after {
  content: "\25BC";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}

.toggle-input {
  display: none;
}
.toggle-input:not(checked) ~ .mega-menu {
  display: none;
}

.toggle-input:checked ~ .mega-menu {
  display: block;
}

.toggle-input:checked + label {
  color: white;
  background: #2c5282; /*@apply bg-blue-800 */
}

.toggle-input:checked ~ label:after {
  content: "\25B2";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}

/* General styling */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
nav {
  background: white;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.5);
}
ul {
  list-style-type: none;
}
a {
  color: white;
  text-decoration: none;
}
.submenu-icon {
  color: #111;
}
a:hover {
  text-decoration: underline;
}
img {
  max-width: 100%;
}
.menu {
  background-color: darkslateblue;
}
.submenu {
  border: 1px solid white;
}
.menu li {
  padding: 10px;
}
.menu-icon {
  color: white;
  margin-left: 10px;
  font-size: 14px;
}
/* Flexbox rules */
.menu {
  display: flex;
  align-items: center;
  text-align: center;
  width: 90vw;
  margin: 10px 5vw;
  height: 60px;
  position: relative;
}
.menu-item {
  flex: 1;
}
.menu-item > a {
  line-height: 40px;
}
.submenu {
  width: 90vw;
  position: absolute;
  top: 60px;
  left: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.submenu-item {
  padding: 15px;
}
.submenu-top {
  display: flex;
  justify-content: space-around;
}
.submenu-bottom {
  display: flex;
}
.submenu-bottom-item {
  flex: 1;
}
/* Submenu general styling */
.submenu-top li {
  padding-left: 0;
}
.submenu a {
  color: #111;
}
.submenu-top-item {
  max-width: 33.333%;
}
.submenu .submenu-title {
  font-weight: bold;
  color: darkslateblue;
}
.submenu .submenu-title:hover {
  text-decoration: none;
}
.submenu-list,
.submenu-bottom {
  margin-top: 10px;
}

/* Submenu: Thumbnail list */
.thumb-list .submenu-list > li {
  display: flex;
  align-items: center;
}
.submenu-thumbnail {
  margin-right: 10px;
}

/* Submenu: Description list */
.desc-list .submenu-list > li {
  display: flex;
  flex-direction: column;
}
.submenu-desc {
  margin-top: 10px;
  color: #555;
}

/* Submenu: Icon list */
.submenu-icon {
  width: 32px;
}

/* Submenu bottom */
.submenu-bottom-title {
  padding-left: 10px;
}
.submenu-bottom figcaption {
  margin-top: 5px;
  font-weight: bold;
}
.submenu-bottom a:hover {
  text-decoration: none;
}
/* Hover rule */
.submenu {
  display: none;
}
.menu-item:hover .submenu {
  display: flex;
}
.menu-item:hover > a {
  text-decoration: underline;
}

nav {
  position: fixed;
  z-index: 99;
  width: 100%;
  background: #fefeff;
}
nav .wrapper {
  /* position: relative; */
  max-width: 1300px;
  padding: 0px 30px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .logo a {
  color: #080505;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.wrapper .nav-links {
  display: inline-flex;
}
.nav-links li {
  list-style: none;
}
.nav-links li a {
  color: hsl(208, 90%, 19%);
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
  vertical-align: text-top;
}
.nav-links li a:hover {
  color: hsl(103, 86%, 29%);
}

.nav-links .mobile-item {
  display: none;
}
.nav-links .drop-menu {
  position: absolute;
  background: #ffffff;
  width: 180px;
  line-height: 25px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  /* box-shadow: 0 6px 10px rg; */
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 70px;

  opacity: 1;
  visibility: visible;
}
.drop-menu li a {
  width: 100%;
  display: flex;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}
.mega-box {
  position: absolute;

  left: 0;
  width: 100%;
  /* padding: 0 30px; */
  top: 85px;
  opacity: 0;
  visibility: hidden;
}
.mega-box .content {
  background: white;
  padding: 25px 20px;
  /* display:flex; */

  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(51, 44, 44, 0.979);
}
.mega-box .content .row li {
  display: inline;
  margin: 0 10px;
  width: 20%;
}
.mega-box .content .row ul {
  padding-left: 20%;
}

.mega-box .content .row {
  width: 100%;
  /* height: 70px; */
  /* width: calc(25% - 30px); */
  line-height: 25px;
}
.mega-box .content .row header {
  width: 100%;
  /* width: calc(25% - 30px); */
  line-height: 45px;
}

/* .content .row header a, */
.content .row header {
  /* display: inline; */
  position: relative;
  border: 0;
  height: 0%;
  color: #0a0404;
  font-size: 20px;
  font-weight: 500;
  background-color: white;
  font-weight: 500;
  font-size: 1.2em;
  /* line-height: 25px; */
  border-top: black;
}
.content .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid white;
}
.row .mega-links li {
  padding: 0 20px;
}
.row .mega-links li a {
  padding: 0px;

  margin: 0%;
  display: inline-block;
  width: 25%;
  /* padding: 0 20px; */
  color: #605b5b;
  font-size: 15px;
  /* display: block; */
}
.row .mega-links li a:hover {
  color: #4f9a05;
}
.wrapper .btn {
  color: rgb(7, 6, 6);
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

@media screen and (max-width: 970px) {
  .wrapper .btn {
    display: block;
  }
  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: hsl(204, 26%, 96%);
    display: block;
    padding: 5px 10px;
    line-height: 40px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px hsl(0, 41%, 3%);
    transition: all 0.6s ease;
  }
  .row .mega-links li a {
    padding: 10px;
    width: 100%;
    display: block;
    color: #605b5b;
    font-size: 12px;
    line-height: 10px;
    /* display: block; */
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: hsl(200, 100%, 99%);
  }
  ::-webkit-scrollbar-thumb {
    background: #ffffff;
  }
  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }
  .fas {
    color: #000;
  }

  .nav-links li a {
    padding: 0 10px;
    display: block;

    font-size: 20px;
  }
  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box,
  #showMega3:checked ~ .mega-box,
  #showMega4:checked ~ .mega-box,
  #showMega2:checked ~ .mega-box {
    max-height: 100%;
  }
  .nav-links .desktop-item {
    display: none;
  }
  .nav-links .mobile-item {
    display: block;
    color: #050101;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover {
    background: #fdfeff;
  }
  /* .drop-menu li{
    margin: 0;
    padding: 0;
  } */
  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }
  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .mega-box .content .row li {
    width: 100%;
    height: 25px;
    display: flex;
    padding: 5px;
    line-height: 20px;
  }

  .mega-box .content .row ul {
    padding-left: 0%;
  }
  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    /* padding: 20px 20px 0 20px; */
  }
  .mega-box .content .row {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-top: 1px solid rgb(255, 255, 255);
  }

  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }
  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li {
    margin: 0;
  }
  .content .row header {
    font-size: 15px;
    font-weight: 500;
    margin-left: 0;
    padding-left: 0;
  }
  .content .row header {
    font-size: 15px;
    font-weight: 500;
    margin-left: 0;
    padding-left: 0;
  }

  .content .row header a {
    font-size: 15px;
    padding-left: 0;
    margin-left: 0;
  }
}
nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div {
  font-size: 45px;
  font-weight: 600;
}

#menu {
  transition: all ease-in-out 0.5s;
}

/* body.x-navbar-fixed-top-active .x-navbar-wrap {
  height: auto;
}

.home .x-logobar,
.home  .x-navbar, .home .x-topbar  {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
}


.x-navbar .desktop .x-nav>li>a {
border-right: 0px ;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.card4 {
  border-radius: 4px;
  background: #fff;
  color: gray;
  /* box-shadow: 0 6px 10px rgba(9, 8, 8, 0.08), 0 0 6px rgba(0,0,0,.05); */
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 25px;
  cursor: pointer;
  margin: 20px;

  width: 400px;
  height: 400px;
}
.card4 .icon {
  padding: 5px;
  color: rgba(17, 138, 230, 0.811);
}

.card4:hover .icon,
.card4:hover p {
  color: brown;
}

.card3 {
  border-radius: 4px;
  background: #fff;
  color: gray;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 80px 18px 36px;
  cursor: pointer;
  /* margin: 20px;
  width: 400px;
  height: 300px;
  */
}

.card3:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  color: white;
  background-color: rgb(14, 107, 157);
  /* color: #fff; */
}
.card3 h3 {
  color: rgb(9, 87, 132);
}
.card3:hover h3 {
  color: white;
}
.card3:hover p {
  color: white;
}

.card3 h3 {
  font-weight: 600;
}

.card3 img {
  position: absolute;
  top: 20px;
  right: 15px;
  max-height: 120px;
}

@media (max-width: 990px) {
  .card3 {
    margin: 20px;
    /* width: 90%;
  height: auto; */
  }
}

/* card */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* min-height: 100vh; */
  padding: 0px;
  background: rgba(235, 235, 235, 0.863);
}

.card {
  height: 400px;
  width: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  margin: 20px;
  cursor: pointer;
}

.card:before {
  content: "";
  position: absolute;
  height: 500px;
  width: 500px;
  background: linear-gradient(#8a1f04, #990505);
  animation: spin 2s linear infinite;
}

.card .content2 {
  height: 97%;
  width: 96%;
  background: rgb(236, 237, 240);
  border-radius: 10px;
  /* color: #242464; */
  padding: 0px;
  z-index: 100;
  text-align: center;
  position: relative;
  transition: 0.5s ease-in-out;
}

.card .content2 h1 {
  margin-top: 10px;
  font-size: px;
  font-weight: 600;
}

.card .content2 p {
  margin-top: 20px;
  text-align: justify;
  font-size: 20px;
}

.card:hover .content2 {
  background: rgba(0, 0, 0, 0.6);
  color: rgb(253, 247, 247);
}

.card .content2:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  top: 0;
  transition: 0.5s ease-in-out;
}

.card:hover .content2:after {
  background: rgba(31, 28, 28, 0.041);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* hover */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container4 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px 0;
}

.container4 .box {
  position: relative;
  width: 320px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 30px;
  transition: 0.5s;
}

.container4 .box::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  text-decoration: none;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
}

.container4 .box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
  filter: blur(30px);
}

.container4 .box:hover:before,
.container4 .box:hover:after {
  transform: skewX(0deg);
  left: 20px;
  width: calc(100% - 90px);
}

.container4 .box:before,
.container4 .box:after {
  background: linear-gradient(315deg, #0aeee6d0, #1326a2);
}

.container4 .box span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
}

.container4 .box span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.1s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.container4 .box:hover span::before {
  top: -50px;
  left: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

.container4 .box span::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  animation-delay: -1s;
}

.container4 .box:hover span:after {
  bottom: -50px;
  right: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

@keyframes animate {
  0%,
  100% {
    transform: translateY(10px);
  }

  50% {
    transform: translate(-10px);
  }
}

.container4 .box .content4 {
  position: relative;
  left: 0;
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  transform: 0.5s;
  color: #fff;
}

.container4 .box:hover .content4 {
  left: -25px;
  padding: 60px 40px;
}

.container4 .box .content4 h2 {
  font-size: 2em;
  color: white;
  margin-bottom: 10px;
  font-size: 25px;
}

.container4 .box .content4 p {
  font-size: 1.1em;
  margin-bottom: 10px;
  line-height: 1.4em;
  font-size: 16px;
  color: white;
}

.container4 .box .content4 a {
  display: inline-block;
  font-size: 1.1em;
  color: #111;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  margin-top: 5px;
}

.container4 .box .content4 a:hover {
  background: #ffcf4d;
  border: 1px solid rgba(255, 0, 88, 0.4);
  box-shadow: 0 1px 15px rgba(1, 1, 1, 0.2);
}

/* footer */

/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}
body {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
}
ul { list-style: none; }
a { text-decoration: unset; }
a:hover { text-decoration: underline; }
ul > li > a { color:#fff; }
ul > li { line-height: 1.5em; } */

.footer {
  display: block;
  width: 100%;
  position: relative;
  background: #fcfcfc;
  padding: 25px 25px 50px 25px;
  color: #555;
  /* border-top-left-radius: 20px;
  border-right-radius: 20px; */
}

.start-learning {
  position: absolute;
  left: 0;
  right: 0;
  top: -150px;
}
.footer .inner {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  column-gap: 20px;
  row-gap: 20px;
  max-width: 1180px;
  margin: auto;
  text-align: left;
}
.main-logo {
  position: relative;
  display: flex;
  align-items: center;
}
.main-logo .logo {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.main-logo .logo > img {
  display: block;
  width: 100%;
  min-width: 40px;
}
.logo-info {
  text-align: left;
  line-height: 20px;
}
.text {
  font-size: 17px;
  line-height: 17px;
  color: rgb(12, 11, 11);
  letter-spacing: 0.18em;
  font-weight: 600;
}
.copyright {
  color: rgb(2, 2, 2);
  font-size: 12px;
  line-height: 12px;
}
.footer .column {
  width: 100%;
  font-size: 14px;
  text-align: left;
}
.footer .column .column-title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0.5em;
  color: rgb(0, 0, 0);
}

.footer .column a {
  color: rgb(51, 49, 49);
}
.footer .column a:hover {
  color: rgb(13, 163, 209);
}

.footer .column .column-title:not(:first-child) {
  margin-top: 1em;
}
.illustration {
  left: 8%;
  position: absolute;
  top: -28px;
}

.section-title {
  font-size: 44px;
  font-weight: 700;
  color: rgb(0, 0, 0);
}
.section-sub-heading {
  font-size: 22px;
  font-weight: 400;
  color: rgb(0, 0, 0);
}
.section-sub-heading span {
  opacity: 0.6;
}
.section-sub-heading strong {
  font-weight: 600;
}

@media only screen and (max-width: 990px) and (min-width: 200px) {
  body {
    height: 200vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .footer .inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .start-learning {
    position: unset;
  }
  .footer-start {
    width: 100%;
    display: block;
    padding: 30px 20px;
    margin: 30px 0;
  }
  .section-title {
    font-size: 30px;
  }
  .section-sub-heading {
    font-size: 18px;
  }
  footer.footer {
    padding-top: 0;
  }
  a.button {
    height: 50px;
    margin-top: 10px;
  }
  .column.is-logo {
    order: 4;
  }
}

/* .bgofall {
  padding-top: 100px;
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
  width: 100%;
  height: 90vh;
  
} */

/* .bgofall {
 position: relative; */
/* height:; */
/* }
 .bgofall::after {
   content: '';
   background:  linear-gradient(rgba(6, 6, 6, 0.235), rgba(16, 16, 16, 0.8));  
    
   position: absolute;
   color: white;
   background-size: cover;
   background-attachment: fixed;
   z-index: -1;
   width: 100%;
   height: auto;
   top: 0;
   left: 0;
    right: 0;
    bottom: 0;
   } */

.bgofall2 {
  /* position: fixed; */
  /* display: none; */
  width: 100%;
  height: 100%;
  /* top: 0; */
  left: 0;
  right: 0;
  /* bottom: 0; */
  background-color: rgba(31, 29, 29, 0.5);
  z-index: 2;
  /* cursor: pointer; */
}
.heroheader {
  border: 1px solid black;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #1226aa 10.8%, #007399 77.3%);
  border-bottom-right-radius: 150px;
}
.imggrid {
  display: flex;
  justify-content: center;
  align-items: center;
}
.right_top_curve {
  padding: 20px;
  width: 400px;
  height: 400px;
  border-bottom-left-radius: 130px;
}
.textgrid {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .smallcard{
    
  
  width: 400px;
  height: 222px;
  
  
  
  } */

.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 60vh;
  padding: 2.5rem 2rem 2rem 2rem;
}
.skillsHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.skillsHeader h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
}

.skillsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 3.5rem;
  padding: 0 2rem;
}

.skill--scroll {
  width: 100%;
  margin: 0 2rem;
}

.skill--box {
  /* background: #FAFAFA;
    color: blueviolet; */
  box-shadow: 0px 10px 20px rgba(78, 3, 3, 0.856);
  border-radius: 10px;
  width: 160px;
  height: 160px;
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  transition: 300ms ease-in-out;
}

.skill--box:hover {
  transform: scale(1.15);
}

.skill--box > img {
  /* pointer-events: none; */
  height: 100%;
  /* width: 100%; */
}

.skill--box h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  margin-top: 1rem;
}

.marquee {
  padding: 3rem 0;
}

@media (min-width: 992px) and (max-width: 1380px) {
  .skills {
    padding: 2rem 1rem 1rem 1rem;
  }
  .skillsContainer {
    padding: 1.5rem;
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 992px) {
  .skills {
    padding: 1rem;
    min-height: 100%;
  }
  .skillsContainer {
    padding: 1rem;
    margin: 1rem 0;
  }
  .skillsHeader h2 {
    font-size: 2.2rem;
  }
  .skill--box {
    width: 150px;
    height: 150px;
    margin: 1.2rem;
    padding: 2rem 1rem;
  }

  .skill--box > img {
    /* pointer-events: none; */
    height: 100%;
    /* width: 100%; */
  }

  .skill--box h3 {
    font-size: 20px;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .skills {
    padding: 0.5rem;
  }
  .skillsContainer {
    padding: 0.5rem;
  }
  .skillsHeader h2 {
    font-size: 2rem;
  }

  .skill--scroll {
    width: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .heroheader {
    border: 1px solid black;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #1226aa 10.8%, #007399 77.3%);
    border-bottom-right-radius: 40px;
  }
  .skill--box {
    width: 135px;
    height: 135px;
    margin: 1.2rem;
    padding: 2rem 1rem;
  }

  .skill--box > img {
    /* pointer-events: none; */
    height: 100%;
    /* width: 100%; */
  }
  .skillsHeader h2 {
    font-size: 2rem;
  }

  .skill--box h3 {
    font-size: 18px;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 400px) {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@media only screen and (device-width: 768px) {
}
